import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'referralPageContent',
  props: {
    isLoggedIn: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    isLoading: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    title: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    subtitle: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    explanations: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    rewardType: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    couponCode: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    rewardButtonLabel: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    myRewardsPageUrl: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    isError: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
  },
  methods: {},
  events: {
    rewardButtonClick: {},
  },
});
