import { ComponentRef } from '@wix/yoshi-flow-editor';
import { PageRef, WidgetInstallationType } from '@wix/editor-platform-sdk-types';

import { EditorScriptContext, PageConfig } from '../types';

interface AddBlocksWidgetParams {
  pageRef: PageRef;
  pageConfig: PageConfig;
}

export const addBlocksWidget = async (
  { editorSDK }: EditorScriptContext,
  { pageRef, pageConfig }: AddBlocksWidgetParams,
): Promise<ComponentRef> => {
  const { widgetId, presets, classicEditorSize, responsiveEditorWidth, verticalMargin } = pageConfig;

  const pageChildren = await editorSDK.components.getChildren('', {
    componentRef: pageRef as ComponentRef,
  });

  // Target existing "Page Section" component as widget container
  const containerRef: ComponentRef = pageChildren[1] || pageRef;

  return editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    installationType: WidgetInstallationType.Closed,
    containerRef,
    layout: {
      ...(classicEditorSize.stretched && {
        docked: {
          left: {
            px: 0,
            vw: 0,
          },
          right: {
            px: 0,
            vw: 0,
          },
        },
        x: 0,
        y: verticalMargin ?? 0,
      }),
      fixedPosition: false,
      width: classicEditorSize.width,
      height: classicEditorSize.height,
      scale: 1,
      rotationInDegrees: 0,
    } as any,
    layouts: {
      componentLayout: {
        hidden: false,
        height: {
          type: 'auto',
        },
        minWidth: {
          type: 'px',
          value: 0,
        },
        minHeight: {
          type: 'px',
          value: 0,
        },
        type: 'ComponentLayout',
        width: responsiveEditorWidth as any,
      },
      itemLayout: {
        id: '',
        type: 'GridItemLayout',
        gridArea: {
          columnStart: 1,
          columnEnd: 1,
          rowStart: 1,
          rowEnd: 1,
        },
        alignSelf: 'center',
        justifySelf: 'center',
        margins: {
          top: { type: 'px', value: verticalMargin ?? 0 },
          right: { type: 'px', value: 0 },
          bottom: { type: 'px', value: verticalMargin ?? 0 },
          left: { type: 'px', value: 0 },
        },
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
    },
    scopedPresets: {
      desktop: {
        layout: presets.desktop,
        style: presets.desktop,
      },
      mobile: {
        layout: presets.mobile,
        style: presets.mobile,
      },
      tablet: {
        layout: presets.mobile,
        style: presets.mobile,
      },
    },
  });
};
