import { createPage } from './createPage';
import { showProgressBar } from './progressBar';
import { EditorScriptContext } from '../types';
import { referFriendPage, referralPage } from '../pages';

export const installApp = async (context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;
  const { t } = flowAPI.translations;
  let installError: unknown;

  // Show custom app install progress bar as Blocks apps do not show standard one
  const { setNextProgressBarStep, hideProgressBar } = await showProgressBar(context, {
    title: t('install-progress-panel.title'),
    initialStepTitle: t('install-progress-panel.step.preparing'),
    totalSteps: 5,
  });

  try {
    const referFriendPageRef = await editorSDK.document.transactions.runAndWaitForApproval('', async () => {
      await setNextProgressBarStep(t('install-progress-panel.step.configuring'));
      await createPage(context, referralPage);
      await setNextProgressBarStep();
      const pageRef = await createPage(context, referFriendPage);
      await setNextProgressBarStep();

      return pageRef;
    });

    await setNextProgressBarStep(t('install-progress-panel.step.optimizing'));
    await editorSDK.pages.navigateTo('', { pageRef: referFriendPageRef });
    await setNextProgressBarStep(t('install-progress-panel.step.done'));
  } catch (error) {
    installError = error;
  }

  await hideProgressBar(installError);

  if (installError) {
    throw installError;
  }
};
